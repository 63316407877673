/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { createEmptyStateResource, StateResource } from '@alfa-client/tech-shared';
import {
  NotificationsSendFor,
  UserSettings,
  UserSettingsLinkRel,
  UserSettingsResource,
} from '@alfa-client/user-settings-shared';

@Component({
  selector: 'alfa-user-settings-email-benachrichtigung',
  templateUrl: './user-settings-email-benachrichtigung.component.html',
  styleUrls: ['./user-settings-email-benachrichtigung.component.scss'],
})
export class UserSettingsEmailBenachrichtigungComponent {
  @Input() userSettings: StateResource<UserSettingsResource> =
    createEmptyStateResource<UserSettingsResource>();

  @Output() valueChanged: EventEmitter<boolean> = new EventEmitter();

  readonly userSettingsLinkRel = UserSettingsLinkRel;

  public isChecked(userSettings: UserSettings): boolean {
    return userSettings.notificationsSendFor === NotificationsSendFor.ALL;
  }
}
