/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import {
  BinaryFileListResource,
  DownloadBinaryFileAsPdfAction,
} from '@alfa-client/binary-file-shared';
import {
  ApiError,
  ApiErrorAction,
  StateResource,
  formatFullDateWithoutSeperator,
} from '@alfa-client/tech-shared';
import { VorgangHeaderLinkRel, VorgangWithEingangResource } from '@alfa-client/vorgang-shared';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { getUrl } from '@ngxp/rest';
import { Observable } from 'rxjs';
import { PostfachMailResource } from '../postfach.model';

import * as BinaryFileActions from '@alfa-client/binary-file-shared';
import * as PostfachActions from './postfach.actions';
import * as PostfachSelectors from './postfach.selectors';

@Injectable({ providedIn: 'root' })
export class PostfachFacade {
  constructor(private readonly store: Store) {}

  public startDownloadPdf(): void {
    this.store.dispatch(PostfachActions.startDownloadAsPdf());
  }

  public isDownloadPdfInProgress(): Observable<boolean> {
    return this.store.select(PostfachSelectors.isDownloadPdfInProgress);
  }

  public downloadPdf(vorgangWithEingang: VorgangWithEingangResource): void {
    this.store.dispatch(
      BinaryFileActions.downloadPdf(this.createDownloadPdfProps(vorgangWithEingang)),
    );
  }

  createDownloadPdfProps(
    vorgangWithEingang: VorgangWithEingangResource,
  ): DownloadBinaryFileAsPdfAction {
    return {
      uri: getUrl(vorgangWithEingang, VorgangHeaderLinkRel.POSTFACH_MAILS),
      fileName: this.buildFileName(vorgangWithEingang),
      successAction: PostfachActions.downloadAsPdfSuccess,
      failureAction: this.createFailureAction,
    };
  }

  buildFileName(vorgangWithEingang: VorgangWithEingangResource): string {
    return `${vorgangWithEingang.nummer}_${formatFullDateWithoutSeperator(new Date())}_Nachrichten.pdf`;
  }

  createFailureAction(apiError: ApiError): ApiErrorAction & TypedAction<string> {
    return PostfachActions.downloadAsPdfFailed({ apiError });
  }

  public getAttachmentList(): Observable<StateResource<BinaryFileListResource>> {
    return this.store.select(PostfachSelectors.attachmentList);
  }

  public loadAttachmentList(postfachNachricht: PostfachMailResource): void {
    this.store.dispatch(PostfachActions.loadAttachmentList({ postfachNachricht }));
  }

  public clearAttachmentList(): void {
    this.store.dispatch(PostfachActions.clearAttachmentList());
  }
}
