<!--

    Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<ng-container *ngIf="apiRoot$ | async as apiRoot">
  <ozgcloud-spinner [stateResource]="apiRoot">
    <alfa-header-container [apiRootStateResource]="apiRoot"></alfa-header-container>

    <div class="relative ml-4 mt-16 flex flex-grow items-start justify-between">
      <div class="mat-app-background relative grow"><router-outlet></router-outlet></div>

      <section class="mat-app-background right-nav">
        <alfa-build-info
          *ngIf="apiRoot.resource"
          [apiRoot]="apiRoot.resource"
          data-test-id="build-info"
        ></alfa-build-info>
      </section>
    </div>
  </ozgcloud-spinner>
</ng-container>
