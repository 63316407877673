/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { StateResource } from '@alfa-client/tech-shared';
import { UserProfileResource, UserProfileService } from '@alfa-client/user-profile-shared';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'alfa-user-profile-button-container',
  templateUrl: './user-profile-button-container.component.html',
  styleUrls: ['./user-profile-button-container.component.scss'],
})
export class UserProfileButtonContainerComponent implements OnInit {
  @Input() userProfile: StateResource<UserProfileResource>;

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  showUserProfileSearch$: Observable<boolean>;

  constructor(public userProfileService: UserProfileService) {}

  ngOnInit(): void {
    this.showUserProfileSearch$ = this.getUserProfileSearchVisibility();
  }

  getUserProfileSearchVisibility(): Observable<boolean> {
    return this.userProfileService
      .getUserProfileSearchVisibility()
      .pipe(tap((visibility: boolean) => this.handleVisibilityChange(visibility)));
  }

  handleVisibilityChange(visibility: boolean): void {
    if (visibility) {
      this.menuTrigger.openMenu();
    }
  }

  public showUserProfileSearch(): void {
    this.userProfileService.showUserProfileSearch();
  }

  public hideUserProfileSearch(): void {
    this.userProfileService.hideUserProfileSearch();
  }
}
