/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import {
  EMPTY_STRING,
  StateResource,
  getEmbeddedResources,
  isNotNil,
} from '@alfa-client/tech-shared';
import {
  VorgangHeaderLinkRel,
  VorgangListLinkRel,
  VorgangListResource,
} from '@alfa-client/vorgang-shared';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatButton } from '@angular/material/button';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { isVorgangSearchRoute } from '../../vorgang-util';
import { VorgangSearchFormService } from './vorgang-search.formservice';

@Component({
  selector: 'alfa-vorgang-search',
  templateUrl: './vorgang-search.component.html',
  styleUrls: ['./vorgang-search.component.scss'],
  providers: [VorgangSearchFormService],
})
export class VorgangSearchComponent implements OnInit, OnDestroy {
  @Input() set vorgangSearchPreviewList(
    vorgangListStateResource: StateResource<VorgangListResource>,
  ) {
    this.vorgangListPreview = vorgangListStateResource;
    this.searchResultPreviewLabel = this.buildSearchResultPreviewLabel(vorgangListStateResource);
  }
  @Input() searchString: string;

  @Output() public clearVorgangSearchPreviewList: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('searchSubmitButton') searchSubmitButton: MatButton;
  @ViewChild('searchAutoComplete') searchAutoComplete: MatAutocomplete;

  vorgangListPreview: StateResource<VorgangListResource>;
  searchResultPreviewLabel: string = '';
  previouslyEnteredSearchValue: string;
  private subscription: Subscription;

  readonly vorgangHeaderLinkRel = VorgangHeaderLinkRel;
  readonly vorgangListLinkRel = VorgangListLinkRel;

  constructor(
    public formService: VorgangSearchFormService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.subscription = this.router.events
      .pipe(filter((event: Event) => event instanceof NavigationEnd))
      .subscribe((navigationEnd: Event) => {
        if (isVorgangSearchRoute(navigationEnd as NavigationEnd)) {
          this.focus();
        }
      });
  }

  submit(): void {
    this.previouslyEnteredSearchValue = this.formService.getValue();
    this.formService.submit();
    this.blur();
  }

  blur(): void {
    this.searchInput.nativeElement.blur();
  }

  focusIn(): void {
    this.previouslyEnteredSearchValue = this.formService.getValue();
  }

  focusOut(event: FocusEvent): void {
    if (!this.isRelatedTargetSearchButton(event) && !this.isMatOption(event.relatedTarget)) {
      this.formService.setSearchValue(this.previouslyEnteredSearchValue);
    }
  }

  buildSearchResultPreviewLabel(
    vorgangListStateResource: StateResource<VorgangListResource>,
  ): string {
    const previewListLength = getEmbeddedResources(
      vorgangListStateResource,
      this.vorgangListLinkRel.VORGANG_HEADER_LIST,
    )?.length;
    if (previewListLength === 1)
      return 'Ein Vorschlag wird angezeigt, nutze Pfeiltaste nach unten, um den zu erreichen';
    if (previewListLength > 1)
      return `${previewListLength} Vorschläge werden angezeigt, nutze Pfeiltaste nach unten, um diese zu erreichen`;
    return EMPTY_STRING;
  }

  private isRelatedTargetSearchButton(event: FocusEvent): boolean {
    return event.relatedTarget === this.searchSubmitButton._elementRef.nativeElement;
  }

  //Hack|Workaround -> schnellstmoeglich wieder ausbauen!
  private isMatOption(eventTarget: EventTarget) {
    return isNotNil(eventTarget) && (<any>eventTarget).nodeName + '' === 'MAT_OPTION';
  }

  public handleSearchClearButton(): void {
    this.clearVorgangSearchPreviewList.emit();
    this.formService.clearSearchField();
    this.focus();
  }

  focus(): void {
    this.searchInput.nativeElement.focus();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
