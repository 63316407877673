/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Component } from '@angular/core';
import { StateResource } from '@alfa-client/tech-shared';
import {
  NotificationsSendFor,
  UserSettings,
  UserSettingsResource,
  UserSettingsService,
} from '@alfa-client/user-settings-shared';
import { Observable } from 'rxjs';

@Component({
  selector: 'alfa-user-settings-email-benachrichtigung-container',
  templateUrl: './user-settings-email-benachrichtigung-container.component.html',
  styleUrls: ['./user-settings-email-benachrichtigung-container.component.scss'],
})
export class UserSettingsEmailBenachrichtigungContainerComponent {
  userSettings$: Observable<StateResource<UserSettingsResource>>;

  constructor(private userSettingsService: UserSettingsService) {}

  ngOnInit(): void {
    this.userSettings$ = this.userSettingsService.getUserSettings();
  }

  changeNotificationsSendFor(toggleValue: boolean): void {
    this.userSettingsService.setUserSettings(this.createUserSettings(toggleValue));
  }

  private createUserSettings(toggleValue: boolean): UserSettings {
    const notificationsSendFor: NotificationsSendFor =
      toggleValue ? NotificationsSendFor.ALL : NotificationsSendFor.NONE;
    return { notificationsSendFor };
  }
}
