import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  QueryList,
  ViewChild,
} from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { ButtonToggleComponent } from '../button-toggle/button-toggle.component';

@Component({
  selector: 'ozgcloud-button-toggle-group',
  templateUrl: './button-toggle-group.component.html',
  styleUrls: ['./button-toggle-group.component.scss'],
})
export class ButtonToggleGroupComponent implements AfterContentInit {
  @Input() name: string;
  @Input() default: string;

  @ViewChild('group', { static: true }) buttonToggleGroup: MatButtonToggleGroup;

  @ContentChildren(ButtonToggleComponent) componentAInstances: QueryList<ButtonToggleComponent>;

  ngAfterContentInit(): void {
    this.addButtonsToGroup();
  }

  addButtonsToGroup(): void {
    this.componentAInstances.forEach(
      (component) => (component.matButtonToggle.buttonToggleGroup = this.buttonToggleGroup),
    );
  }
}
