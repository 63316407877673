/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponseBase,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Original Thread: https://github.com/angular/angular/issues/19888
// 17.11.2022 Updated/Current Thread: https://github.com/angular/angular/issues/19148
// When request of type Blob, the error is also in Blob instead of object of the json data
// Der kopierte Code ist minimal richtung CleanCode optimiert
@Injectable()
export class HttpBinaryFileInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((response: HttpResponseBase) => {
        if (this.shouldConvertToJson((<any>response).error)) {
          return this.convertToJson((<HttpErrorResponse>response).error);
        }
        return throwError(response);
      }),
    );
  }

  private shouldConvertToJson(err: HttpResponseBase): boolean {
    return (
      err instanceof HttpErrorResponse &&
      err.error instanceof Blob &&
      err.error.type === 'application/json'
    );
  }

  convertToJson(errorResponse: HttpErrorResponse): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = (event: Event) => {
        try {
          reject(this.buildResponse(errorResponse, event));
        } catch (error) {
          reject(errorResponse);
        }
      };
      reader.onerror = (e) => reject(errorResponse);
      reader.readAsText(errorResponse.error);
    });
  }

  buildResponse(err: HttpResponseBase, event: Event): HttpErrorResponse {
    const errmsg = JSON.parse((<any>event.target).result);
    return new HttpErrorResponse({
      error: errmsg,
      headers: err.headers,
      status: err.status,
      statusText: err.statusText,
      url: err.url,
    });
  }
}
