<!--

    Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<ozgcloud-spinner [stateResource]="userProfileStateResource" diameter="30" padding="3">
  <div
    [matTooltipDisabled]="disableTooltip"
    data-test-class="user-profile-icon"
    [class.initials]="!!userProfileStateResource.resource || userProfileStateResource.error"
    class="user-profile"
    [matTooltip]="tooltip"
  >
    <ng-container *ngIf="userProfileStateResource.resource; else noUser">
      <span data-test-class="user-profile-assigned">{{ initials }}</span>
    </ng-container>
    <ng-template #noUser>
      <mat-icon *ngIf="!userProfileStateResource.error" data-test-class="user-profile-unassigned"
        >account_circle_outline</mat-icon
      >
      <span
        *ngIf="errorMessageCode === messageCode.RESOURCE_NOT_FOUND"
        data-test-class="user-profile-user-not-found"
        >!</span
      >
      <mat-icon
        *ngIf="errorMessageCode === messageCode.SERVICE_UNAVAILABLE"
        class="unavailable"
        data-test-class="user-profile-service-unavailable"
        >error_outline</mat-icon
      >
    </ng-template>
    <div class="picture"></div>
  </div>
</ozgcloud-spinner>
