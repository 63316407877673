import { Resource, ResourceUri, getUrl, hasLink } from '@ngxp/rest';
import { isEqual, isNull } from 'lodash-es';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  filter,
  first,
  map,
  startWith,
  tap,
} from 'rxjs';
import { isNotNull, isNotUndefined } from '../tech.util';
import { CreateResourceData, ListItemResource, ListResourceServiceConfig } from './resource.model';
import { ResourceRepository } from './resource.repository';
import { mapToFirst, mapToResource } from './resource.rxjs.operator';
import {
  ListResource,
  StateResource,
  createEmptyStateResource,
  createStateResource,
  getEmbeddedResources,
  isEmptyStateResource,
  isInvalidResourceCombination,
  isLoadingRequired,
  isStateResoureStable,
} from './resource.util';

/**
 * B = Type of baseresource
 * T = Type of listresource
 * I = Type of items in listresource
 */
export class ResourceListService<
  B extends Resource,
  T extends ListResource,
  I extends ListItemResource,
> {
  readonly nextLink: string = 'next';
  readonly prevLink: string = 'prev';

  readonly listResource: BehaviorSubject<StateResource<T>> = new BehaviorSubject(
    createEmptyStateResource(),
  );

  readonly selectedResource: BehaviorSubject<StateResource<I>> = new BehaviorSubject(
    createEmptyStateResource(),
  );

  baseResource: B = null;

  constructor(
    private config: ListResourceServiceConfig<B>,
    private repository: ResourceRepository,
  ) {}

  public getList(): Observable<StateResource<T>> {
    return combineLatest([this.listResource.asObservable(), this.getConfigResource()]).pipe(
      tap(([stateResource, configResource]) => this.handleChanges(stateResource, configResource)),
      tap(([, configResource]) => this.handleNullConfigResource(configResource)),
      filter(([stateResource]) => !isInvalidResourceCombination(stateResource, this.baseResource)),
      mapToFirst<T, B>(),
      startWith(createEmptyStateResource<T>(true)),
    );
  }

  private getConfigResource(): Observable<B> {
    return this.config.baseResource.pipe(filter(isStateResoureStable<B>), mapToResource<B>());
  }

  handleChanges(stateResource: StateResource<T>, configResource: B): void {
    if (!isEqual(this.baseResource, configResource)) {
      this.handleConfigResourceChanges(configResource);
    } else if (this.shouldLoadResource(stateResource, configResource)) {
      this.loadListResource(configResource, this.config.listLinkRel);
    }
  }

  handleConfigResourceChanges(newConfigResource: B): void {
    this.baseResource = newConfigResource;
    if (this.hasListLinkRel() && isStateResoureStable(this.listResource.value)) {
      this.loadListResource(this.baseResource, this.config.listLinkRel);
    } else if (!this.hasListLinkRel() && isStateResoureStable(this.listResource.value)) {
      this.clearCurrentListResource();
    }
  }

  private hasListLinkRel(): boolean {
    return hasLink(this.baseResource, this.config.listLinkRel);
  }

  shouldLoadResource(stateResource: StateResource<T>, configResource: B): boolean {
    return isNotNull(configResource) && isLoadingRequired(stateResource) && this.hasListLinkRel();
  }

  handleNullConfigResource(configResource: B): void {
    if (this.shouldClearStateResource(configResource)) {
      this.clearCurrentListResource();
    }
  }

  private clearCurrentListResource(): void {
    this.listResource.next(createEmptyStateResource());
  }

  shouldClearStateResource(configResource: B): boolean {
    return isNull(configResource) && !isEmptyStateResource(this.listResource.value);
  }

  public create(toCreate: unknown): Observable<Resource> {
    this.verifyBeforeCreation();
    return this.repository.createResource(
      this.buildCreateResourceData(toCreate, this.config.createLinkRel),
    );
  }

  private verifyBeforeCreation(): void {
    this.verifyValidListResource();
    this.throwErrorOn(!this.isCreateLinkPresent(), 'No creation link exists.');
  }

  private buildCreateResourceData(toCreate: any, linkRel: string): CreateResourceData<T> {
    return {
      resource: this.getListResource(),
      linkRel,
      toCreate,
    };
  }

  private isCreateLinkPresent(): boolean {
    return this.hasLinkRel(this.config.createLinkRel);
  }

  public select(uri: ResourceUri): void {
    this.verifyBeforeSelection(uri);
    this.setSelectedResourceLoading();
    this.repository
      .getResource(uri)
      .pipe(first())
      .subscribe((loadedResource) => {
        this.selectedResource.next(createStateResource(<I>loadedResource));
      });
  }

  verifyBeforeSelection(uri: ResourceUri): void {
    this.verifyValidListResource();
    this.throwErrorOn(!this.existsUriInList(uri), 'No entry match with given uri.');
  }

  private verifyValidListResource(): void {
    this.throwErrorOn(isNull(this.getListResource()), 'No list resource available.');
  }

  setSelectedResourceLoading(): void {
    this.selectedResource.next({ ...this.selectedResource.value, loading: true });
  }

  existsUriInList(uri: ResourceUri): boolean {
    const listResources: Resource[] = getEmbeddedResources(
      this.listResource.value,
      this.config.listLinkRel,
    );

    return isNotUndefined(listResources.find((resource) => getUrl(resource) === uri));
  }

  public unselect(): void {
    this.selectedResource.next(createEmptyStateResource());
  }

  public getSelected(): Observable<StateResource<Resource>> {
    return this.selectedResource.asObservable();
  }

  public refresh(): void {
    this.listResource.next({ ...this.listResource.value, reload: true });
  }

  public prev(): void {
    this.throwErrorOn(!this.hasPrevLink(), 'There is no previous page.');
    this.loadListResource(this.getListResource(), this.prevLink);
  }

  public next(): void {
    this.throwErrorOn(!this.hasNextLink(), 'There is no next page.');
    this.loadListResource(this.getListResource(), this.nextLink);
  }

  loadListResource(resource: B | T, linkRel: string): void {
    this.setStateResourceLoading();
    this.repository
      .getListResource(resource, linkRel)
      .pipe(first())
      .subscribe((loadedListResource: T) => this.updateListResource(loadedListResource));
  }

  setStateResourceLoading(): void {
    this.listResource.next(createEmptyStateResource(true));
  }

  updateListResource(listResource: T): void {
    this.listResource.next(createStateResource(listResource));
  }

  private throwErrorOn(condition: boolean, errorMsg: string): void {
    if (condition) throw Error(errorMsg);
  }

  public hasMore(): boolean {
    return this.hasNextLink();
  }

  private hasNextLink(): boolean {
    return this.hasLinkRel(this.nextLink);
  }

  public isFirst(): boolean {
    return !this.hasPrevLink();
  }

  private hasPrevLink(): boolean {
    return this.hasLinkRel(this.prevLink);
  }

  private hasLinkRel(linkRel: string): boolean {
    return hasLink(this.getListResource(), linkRel);
  }

  private getListResource(): T {
    return this.listResource.value.resource;
  }

  public getItems(): Observable<ListItemResource[]> {
    return this.getList().pipe(
      filter((listStateResource: StateResource<T>) => !listStateResource.loading),
      map((listStateResource: StateResource<T>) =>
        getEmbeddedResources<ListItemResource>(
          listStateResource,
          this.config.listResourceListLinkRel,
        ),
      ),
    );
  }
}
