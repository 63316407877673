import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';

@Component({
  selector: 'ozgcloud-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss'],
})
export class ButtonToggleComponent implements OnInit {
  @Input() value: string;
  @Input() buttonToggleGroup: MatButtonToggleGroup;

  @ViewChild('buttonToggle', { static: true }) matButtonToggle: MatButtonToggle;

  ngOnInit(): void {
    this.matButtonToggle.buttonToggleGroup = this.buttonToggleGroup;
  }
}
