/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Injectable } from '@angular/core';
import { getApiErrorFromHttpErrorResponse } from '@alfa-client/tech-shared';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserSettingsRepository } from '../user-settings.repository';
import * as UserSettingsActions from './user-settings.actions';
import { UserSettingsFacade } from './user-settings.facade';

@Injectable()
export class UserSettingsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly repository: UserSettingsRepository,
    private readonly userSettingsFacade: UserSettingsFacade,
  ) {}

  loadUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSettingsActions.loadUserSettings),
      switchMap((action) =>
        this.repository.getUserSettings(action.currentUser).pipe(
          map((userSettings) => UserSettingsActions.loadUserSettingsSuccess({ userSettings })),
          catchError((error) =>
            of(
              UserSettingsActions.loadUserSettingsFailure({
                apiError: getApiErrorFromHttpErrorResponse(error),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  setUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSettingsActions.setUserSettings),
      concatLatestFrom(() => this.userSettingsFacade.getUserSettings()),
      switchMap(([action, userSettingsResource]) =>
        this.repository.setUserSettings(userSettingsResource.resource, action.userSettings).pipe(
          map((userSettings) => UserSettingsActions.setUserSettingsSuccess({ userSettings })),
          catchError((error) =>
            of(
              UserSettingsActions.setUserSettingsFailure({
                apiError: getApiErrorFromHttpErrorResponse(error),
              }),
            ),
          ),
        ),
      ),
    ),
  );
}
