/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { AppService, localStorageDark } from '@alfa-client/app-shared';
import { Observable } from 'rxjs';

@Component({
  selector: 'alfa-user-settings-darkmode-container',
  templateUrl: './user-settings-darkmode-container.component.html',
  styleUrls: ['./user-settings-darkmode-container.component.scss'],
})
export class UserSettingsDarkmodeContainerComponent implements OnInit {
  darkMode$: Observable<boolean>;

  constructor(
    private renderer: Renderer2,
    private appService: AppService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.darkMode$ = this.appService.getDarkMode();

    this.subscribeToDarkMode();
  }

  private subscribeToDarkMode(): void {
    this.darkMode$.subscribe((darkMode) =>
      darkMode ? this.addClass(localStorageDark) : this.removeClass(localStorageDark),
    );
  }

  private addClass(styleClass: string): void {
    this.renderer.addClass(this.document.body, styleClass);
  }

  private removeClass(styleClass: string): void {
    this.renderer.removeClass(this.document.body, styleClass);
  }

  public changeColorMode(darkMode: boolean): void {
    this.appService.setDarkMode(darkMode);
  }
}
