/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { NavigationService } from '@alfa-client/navigation-shared';
import {
  EMPTY_STRING,
  hasMinLength,
  isNotEmpty,
  isNotNil,
  toResourceUri,
} from '@alfa-client/tech-shared';
import {
  VorgangHeaderLinkRel,
  VorgangListService,
  VorgangResource,
} from '@alfa-client/vorgang-shared';
import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Params } from '@angular/router';
import { isEmpty } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, tap } from 'rxjs/operators';

@Injectable()
export class VorgangSearchFormService implements OnDestroy {
  form: FormGroup;

  readonly SEARCH_FIELD: string = 'search';
  readonly PREVIEW_SEARCH_STRING_MIN_LENGTH = 3;

  private searchStringSubscription: Subscription;
  private fromControlSubscription: Subscription;

  public lastSearchString: string;
  public hasSearchString: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private navigationService: NavigationService,
    private vorgangListService: VorgangListService,
  ) {
    this.init();
  }

  private init(): void {
    this.initForm();

    this.subscribeToValueChanges();
    this.subscribeToSearchString();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      [this.SEARCH_FIELD]: new UntypedFormControl(null),
    });
  }

  subscribeToValueChanges(): void {
    this.fromControlSubscription = this.getSearchFormControl()
      .valueChanges.pipe(
        debounceTime(300),
        tap((value) => this.setHasSearchString(value)),
        distinctUntilChanged(),
      )
      .subscribe((value) => this.handleValueChanges(value));
  }

  setHasSearchString(value: string): void {
    this.hasSearchString = isNotEmpty(value);
  }

  handleValueChanges(value: string): void {
    if (this.shouldSearchForPreview(value)) {
      this.vorgangListService.setSearchString(value);
      this.vorgangListService.searchForPreview(value);
    } else {
      this.vorgangListService.clearSearchPreviewList();
    }
  }

  shouldSearchForPreview(value: string): boolean {
    return (
      this.isSearchInputNotPristine() && hasMinLength(value, this.PREVIEW_SEARCH_STRING_MIN_LENGTH)
    );
  }

  isSearchInputNotPristine(): boolean {
    return !this.getSearchFormControl().pristine;
  }

  private subscribeToSearchString(): void {
    this.searchStringSubscription = this.vorgangListService
      .getSearchString()
      .subscribe((searchString: string) => this.patchSearchString(searchString));
  }

  patchSearchString(searchString: string): void {
    const searchStringInputValue: string = this.getSearchFormControl().value;
    if (searchString != searchStringInputValue) {
      this.getSearchFormControl().patchValue(searchString);
    }
    this.lastSearchString = searchString;
  }

  submit(): void {
    if (this.hasSearchString) {
      this.navigationService.search(this.getValue());
    }
  }

  isSameSearchString(): boolean {
    return this.getValue() === this.lastSearchString;
  }

  submitByPreviewList(resource: VorgangResource, searchString: string): void {
    this.getSearchFormControl().patchValue(searchString);
    this.navigateToVorgang(resource);
  }

  public clearSearchField(): void {
    this.patchSearchString(EMPTY_STRING);
    this.navigationService
      .urlChanged()
      .pipe(first())
      .subscribe((params) => this.navigateToVorgangListOnSearch(params));
  }

  private getSearchFormControl(): UntypedFormControl {
    return <UntypedFormControl>this.form.controls[this.SEARCH_FIELD];
  }

  private navigateToVorgang(resource: VorgangResource): void {
    this.navigationService.navigateToVorgang(
      toResourceUri(resource, VorgangHeaderLinkRel.VORGANG_WITH_EINGANG),
    );
  }

  navigateToVorgangListOnSearch(params: Params) {
    if (NavigationService.isSearch(params)) {
      this.navigationService.navigateToVorgangList();
    }
  }

  getValue(): string {
    const value: string = this.getSearchFormControl().value;
    return isEmpty(value) ? null : value;
  }

  public setSearchValue(value: string): void {
    this.getSearchFormControl().setValue(value);
  }

  ngOnDestroy(): void {
    if (isNotNil(this.searchStringSubscription)) this.searchStringSubscription.unsubscribe();
    if (isNotNil(this.fromControlSubscription)) this.fromControlSubscription.unsubscribe();
  }

  public getValueChanges(): Observable<string> {
    return this.getSearchFormControl().valueChanges;
  }
}
