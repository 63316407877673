/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { ListResource, StateResource } from '@alfa-client/tech-shared';
import { HttpErrorResponse } from '@angular/common/http';
import { TypedAction } from '@ngrx/store/src/models';
import { Resource, ResourceUri } from '@ngxp/rest';
import {
  CommandOrder,
  CommandResource,
  CreateCommand,
} from '../../../command-shared/src/lib/command.model';

export interface Vorgang {
  status: VorgangStatus;
  aktenzeichen: string;
  nummer: string;
  createdAt: Date;
  name: string;
  nextFrist: Date;
  hasPostfachNachricht: boolean;
  hasNewPostfachNachricht: boolean;
  antragBewilligt?: boolean;
}

export interface VorgangWithEingang extends Vorgang {
  eingang: Eingang;
}

export interface Eingang {
  id: string;
  antragsteller: Antragsteller;
  zustaendigeStelle: ZustaendigeStelle;
  header: EingangHeader;
  formData: ReadonlyMap<string, object> | object | Array<string>;
  numberOfAttachments: number;
  numberOfRepresentations: number;
}

export interface Antragsteller {
  anrede: string;
  geburtsdatum: string;
  geburtsname: string;
  geburtsort: string;
  nachname: string;
  vorname: string;
  firmaName: string;
  email: string;
  telefon: string;
  strasse: string;
  hausnummer: string;
  plz: string;
  ort: string;
  otherData: ReadonlyMap<string, object>;
}

export interface ZustaendigeStelle {
  email: string;
}

export interface EingangHeader {
  client: string;
  clientId: string;
  createdAt: string;
  customer: string;
  customerId: string;
  formId: string;
  formName: string;
  requestId: string;
  sender: string;
}

export enum VorgangStatus {
  NEU = 'NEU',
  ANGENOMMEN = 'ANGENOMMEN',
  VERWORFEN = 'VERWORFEN',
  IN_BEARBEITUNG = 'IN_BEARBEITUNG',
  BESCHIEDEN = 'BESCHIEDEN',
  ABGESCHLOSSEN = 'ABGESCHLOSSEN',
  WEITERGELEITET = 'WEITERGELEITET',
  ZU_LOESCHEN = 'ZU_LOESCHEN',
}

export interface VorgangResource extends Vorgang, Resource {}

export interface VorgangListResource extends ListResource {
  statistic: VorgangStatistic;
}

export interface VorgangStatistic {
  byStatus: ByStatus;
  wiedervorlagen: number;
  existsWiedervorlageOverdue: boolean;
  ungeleseneNachrichten: number;
}

export interface ByStatus {
  neu: number;
  angenommen: number;
  inBearbeitung: number;
  beschieden: number;
  abgeschlossen: number;
  verworfen: number;
  zuLoeschen: number;
}

export interface VorgangWithEingangResource extends VorgangWithEingang, Resource {}

export interface VorgangDetailSnackBarData {
  message: string;
  showRevokeButton: boolean;
}

export interface CreateForwardCommand extends CreateCommand {
  order: CommandOrder;
  redirectRequest: ForwardRequest;
}

export interface ForwardRequest {
  email: string;
  password: string;
}

export interface CreateAssignUserCommand extends CreateCommand {
  body: AssignUserBody;
}

export interface AssignUserBody {
  assignedTo: ResourceUri;
}

export enum VorgangFilter {
  ALLE,
  MEINE_VORGAENGE,
  NICHT_ZUGEWIESEN,
}

export enum VorgangView {
  NEU,
  ANGENOMMEN,
  IN_BEARBEITUNG,
  BESCHIEDEN,
  ABGESCHLOSSEN,
  VERWORFEN,
  VORGANG_LIST,
  WIEDERVORLAGEN,
  SEARCH,
  ZU_LOESCHEN,
  UNGELESENE_NACHRICHTEN,
}

export interface StatusCommandMap {
  [CommandOrder.VORGANG_ABSCHLIESSEN]: StateResource<CommandResource>;
  [CommandOrder.VORGANG_ANNEHMEN]: StateResource<CommandResource>;
  [CommandOrder.VORGANG_BEARBEITEN]: StateResource<CommandResource>;
  [CommandOrder.VORGANG_BESCHEIDEN]: StateResource<CommandResource>;
  [CommandOrder.VORGANG_VERWERFEN]: StateResource<CommandResource>;
  [CommandOrder.VORGANG_WIEDEREROEFFNEN]: StateResource<CommandResource>;
  [CommandOrder.VORGANG_ZURUECKHOLEN]: StateResource<CommandResource>;
  [CommandOrder.VORGANG_ZURUECKSTELLEN]: StateResource<CommandResource>;
}

export interface AdditionalActions {
  additionalSuccessAction: () => TypedAction<string>;
  additionalFailureAction: (error: HttpErrorResponse) => TypedAction<string>;
}
