/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import {
  ApiError,
  ApiErrorAction,
  StateResource,
  TypedActionCreator,
  TypedActionCreatorWithProps,
} from '@alfa-client/tech-shared';
import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { Resource } from '@ngxp/rest';
import {
  CommandListResource,
  CommandResource,
  CreateCommand,
  CreateCommandProps,
} from '../command.model';

export const publishConcurrentModificationAction: TypedActionCreator = createAction(
  '[Command/API] Concurrent Modification',
);

export interface CommandStateResourceProps {
  commandStateResource: StateResource<CommandResource>;
}

export interface LoadCommandListSuccessProps {
  commandList: CommandListResource;
}
export interface LoadCommandListProps {
  resource: Resource;
  linkRel: string;
  successAction: (
    commandList: CommandListResource,
  ) => LoadCommandListSuccessProps & TypedAction<string>;
  failureAction: (apiError: ApiError) => ApiErrorAction & TypedAction<string>;
}

export const loadCommandList: TypedActionCreatorWithProps<LoadCommandListProps> = createAction(
  '[Command] Load pending commands',
  props<LoadCommandListProps>(),
);

export interface CommandProps {
  command: CommandResource;
}

export interface SnackBarProps {
  command: CommandResource;
  createCommandProps: CreateCommandProps;
}

export interface PollCommandProps {
  command: CommandResource;
  createCommandProps?: CreateCommandProps;
}

export interface RevokeCommandFailureProps {
  command: CommandResource;
  error: ApiError | unknown;
}

export interface CreateCommandFailureProps {
  command: CreateCommand;
  error: ApiError | HttpErrorResponse | unknown;
}

export const createCommand: TypedActionCreatorWithProps<CreateCommandProps> = createAction(
  '[Command] Create command',
  props<CreateCommandProps>(),
);
export const createCommandSuccess: TypedActionCreatorWithProps<CommandProps> = createAction(
  '[Command] Create command Success',
  props<CommandProps>(),
);
export const createCommandFailure: TypedActionCreatorWithProps<CreateCommandFailureProps> =
  createAction('[Command] Create command Failure', props<CreateCommandFailureProps>());
export const pollCreatedCommand: TypedActionCreatorWithProps<PollCommandProps> = createAction(
  '[Command] Poll created command',
  props<PollCommandProps>(),
);

export const showRevokeSnackbar: TypedActionCreatorWithProps<CommandProps> = createAction(
  '[Command] Show Revoke Snackbar',
  props<CommandProps>(),
);
export const showSnackbar: TypedActionCreatorWithProps<SnackBarProps> = createAction(
  '[Command] Show Snackbar',
  props<SnackBarProps>(),
);

export const revokeCommand: TypedActionCreatorWithProps<CommandProps> = createAction(
  '[Command] Revoke command',
  props<CommandProps>(),
);
export const revokeCommandSuccess: TypedActionCreatorWithProps<CommandProps> = createAction(
  '[Command] Revoke command Success',
  props<CommandProps>(),
);
export const revokeCommandFailure: TypedActionCreatorWithProps<RevokeCommandFailureProps> =
  createAction('[Command] Revoke command Failure', props<RevokeCommandFailureProps>());
export const pollRevokedCommand: TypedActionCreatorWithProps<PollCommandProps> = createAction(
  '[Command] Poll revoked command',
  props<PollCommandProps>(),
);
